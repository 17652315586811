@import "src/general/colors";
@import "src/general/sizes";

.page.product{

  .product-header {
    display: flex;
    justify-content: center;
    background-color: $l-5;

    .boundary{
      display: flex;
      justify-content: center;
      gap: 24px;
      height: 300px;

      .section-img {
        position: relative;

        flex: 1;
        background: url("../general/img/usage-mobile_optimized.jpg") center no-repeat;
        background-size: cover;

        display: flex;

        .fade-layer{
          position: absolute;
          width: 50%;
          height: 100%;
          left: 0;
          background: linear-gradient(90deg, rgba(250,250,250,1), rgba(250,250,250,0));
        }
      }
      .section-content{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
        span, p{
          font-size: 16px;
        }
        span{
          color: $sub-content;
        }
        p{
          white-space: pre-line;
        }
      }
    }
    &.desktop{
      .section-img {
        background: url("../general/img/usage-desktop_optimized.jpg") center no-repeat;
        background-size: cover;
      }
    }

  }
  .product-body{
    padding: 60px 0px;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    &.desktop{
      .feature-item {
        .feature-images {
          img{
            border-radius: 8px;
            border: 1px solid $l-20;
          }
        }
      }
    }

    .feature-item{
      position: relative;
      width: 1000px;
      padding: 60px 0px;

      display: flex;
      //justify-content: flex-start;
      justify-content: center;
      gap: 32px;
      //background-color: aqua;

      &:last-child:after{
        display: none;
      }

      &:after{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

        content: "";
        width: 200px;
        height: 1px;
        background-color: $l-30;

      }

      &:nth-child(even){
        flex-direction: row-reverse;
        .feature-content{
          *{
            text-align: right;
          }
        }
      }

      .feature-images{
        display: flex;
        gap: 16px;
        img{
          max-height:500px;
          max-width: 500px;
        }
      }
      .feature-content{
        justify-content: center;
        display: flex;
        flex-direction: column;
        gap: 8px;
        p{
          white-space: pre-wrap;
          font-size: 16px;
          color: $sub-content;
          &.sub{
            opacity: 0.5;
          }
        }
      }
    }
  }
}


@media (max-width: $desktop-breakpoint) {
  .page.product{
    .product-header {
      .boundary {
        padding: 0px;
        height: initial;
        flex-direction: column;
        gap: 0px;

        .section-img{
          min-height: 200px;
          .fade-layer{
            display: none;
          }
        }
        .section-content{
          padding-top: 40px;
          padding-bottom: 60px;
          text-align: center;
        }
      }
    }
    .product-body{
      padding: 40px 0px;

      .feature-item{
        flex-direction: column !important;
        .feature-images{
          justify-content: center;
        }
        .feature-content{
          *{
            text-align: center !important;
          }
        }
      }
    }
  }
}

@media (max-width: $mobile-breakpoint) {
  .page.product{
    .product-body{

      &.mobile{
        .feature-item{
          .feature-images{
            img{
              height: initial !important;
              width: 45% !important;
            }
          }
        }
      }

      &.desktop{
        .feature-item{
          .feature-images{
            img{
              height: initial !important;
              width: 100% !important;
            }
          }
        }
      }

      .feature-item{
        width: 100%;

        .feature-images{
          padding: 0px 32px;
          width:100%;
        }
        .feature-content{
          p{
            font-size: 14px;
          }
        }
      }
    }
  }
}
