@import "src/general/colors";

.page.contact{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  


  .contact-intro{
    position: relative;
    padding: 60px 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    gap: 8px;
    background-color: rgba(60,100,230,0.1);

    &:before{
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background: url("../general/img/medical-consult.png") no-repeat center;
      background-size: cover;
      opacity: 0.4;
    }
  }
  .contact-form{
    width: 100%;
    padding: 40px 30px;
    padding-bottom: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .form-header{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
    }
    .form-body{
      width: 100%;
      max-width: 1000px;
      display: flex;
      flex-direction: column;

      .input-item{
        width: 100%;
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        span{
          color: $sub-content;
          line-height: 28px;
        }
        input, textarea{
          width: 100%;
          border: 1px solid $l-20;
          min-height: 40px;
          padding: 8px;
          border-radius: 8px;
          background-color: $l-5;
        }
        textarea{
          height: 600px;
          width: 100%;
          resize: none;
        }
      }
      .btn-submit{
        margin-top: 24px;
        width: 100%;
        height: 44px;
        border-radius: 8px;
        background-color: $mdoc-ui-blue;
        color: white;
      }
    }
  }
}

.table-container{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-color: #F5F5F5;
  border-width: 1px;
  border-style: solid;
  border-radius: 10;

  th {
    position: relative;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: $mdoc-ui-blue;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 8px;
    padding-bottom: 5px;
    border-bottom-color: black;
    border-width: 2px;
    border-bottom-style: solid;
  
  }
  
  td {
    position: relative;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #323232;
    flex: none;
    order: 2;
    flex-grow: 0;
    border-bottom-color: #F5F5F5;
    border-width: 2px;
    border-bottom-style: solid;
    padding-bottom: 10px;
   
    // margin: 0px 8px;
  }
  
  
  .address_td{
    max-width: 300px;
    word-break:break-all;
  }
  
  Table {
    border-collapse: separate;
    border-spacing: 0 10px;
    width: 90%;
   
  }
  
  .tooltip{
    flex-direction: column;
  
  }
  
  .info_icon{
    width: 16px;
    height: 16px;
    flex: none;
    order: 10;
    flex-grow: 0;
    margin: 0px 16px;
  }
  .join_icon{
    width: 16px;
    height: 16px;
    flex: none;
    order: 10;
    flex-grow: 0;
    margin: 0px 16px;
  
  }
  
  .confirm_icon{
    width: 16px;
    height: 16px;
    flex: none;
    order: 10;
    flex-grow: 0;
    margin: 0px 13px;
  
  }
  .user{
    width: 12px;
    height: 12px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 6px;
  
  }
  
  .patientNumber{
    color: red;
  }
  
}
