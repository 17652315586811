@import "src/general/sizes";
@import "src/general/colors";

.nav-bar{
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(255,255,255,0.8);

  .boundary{
    height: $nav-bar-height;

    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .logo{
      height: 24px;
    }
    .menu-container{
      display: flex;
      gap: 12px;
      align-items: center;

      &.home .menu.home:before,
      &.mobile .menu.mobile:before,
      &.desktop .menu.desktop:before,
      &.contact .menu.contact:before{
        display: block;
        opacity: 1;
      }

      .divider{
        height: 16px;
        width: 1px;
        background-color: $l-30;
      }
      .menu{
        position: relative;
        padding: 0px 12px;
        color: $main-content;
      
        .down{
          width: 100%;
          height: 100%;
          padding: 10px 5px;
          border-radius: 10px;
          border-color: $mdoc-ui-blue;
          border-width: 2px;
          border-style: solid;
          color: #fff;
          
          background-color: $mdoc-ui-blue;

        }

        &.label{
          color: $sub-content;
          pointer-events: none;
        }

       

        &:before{
          position: absolute;
          bottom: -5px;
          left: 0px;
          width: 100%;
          height: 3px;
          content: "";
          background-color: $mdoc-ui-blue;
          display: none;
        }
      }
      
      .product-menus{
        display: flex;
      }
    }
  }
}

.nav-bar .boundary .menu-container .menu::after{
  content: '';
  width: 0;
  height: 3px;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  background:  $mdoc-ui-blue;
  transition: width 0.4s;
}

.nav-bar .boundary .menu-container .menu:hover::after{
  
  width: 100%;
  
}


@media (max-width: $tablet-breakpoint) {
  .nav-bar{
    .boundary{
      justify-content: center;
      .logo{
        display: none;
      }
    }
  }
}

@media (max-width: $mobile-breakpoint) {
  .nav-bar{
    .boundary{
      justify-content: center;
      .logo{
        display: none;
      }
      .menu-container{
        .menu{
          padding: 0px 8px;
        }
        //align-items: flex-end;
        .divider{
          //height: 40px;
        }
        .product-menus{
          //flex-direction: column;
          //align-items: center;
          .menu.label{
            //width: 100%;
            //text-align: center;
            //margin-bottom: 4px;
            //color: $placeholder;
            display: none;
          }
        }
      }
    }
  }
}

