@import "src/general/colors";
@import "src/general/sizes";


.product-summary{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: $l-5;
  
  

  .product{
    display: flex;
    height: 50vh;

    &.mobile{
      flex-direction: row-reverse;
      .section-img{

        background: url("../../general/img/usage-mobile_optimized.jpg") center no-repeat;
        background-size: cover;

        .fade-layer{
          left: 0;
          right: initial;
          background: linear-gradient(90deg, rgba(250,250,250,1), rgba(250,250,250,0));

        }
      }
    }

    .section-content{
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      span, p{
        font-size: 16px;
        text-align: center;
      }
      span{
        font-weight: bold;
        color: $sub-content;
      }
      p{
        white-space: pre-line;
      }
      .btn-learn-more{
        margin-top: 8px;
        display: flex;
        gap: 6px;
        align-items: center;

        span{
          color: $mdoc-ui-blue;
          font-weight: normal;
          //font-size: 14px;
        }
        img{
          width: 15px;
        }
      }

      .download-button-container{
        margin-top: 12px;
        display: flex;
        gap: 12px;

        
        .btn-download-desktop-app{
          position: relative;
          padding: 12px 16px;
          height: 40px;

          display: flex;
          align-items: center;

          gap: 6px;

          border-radius: 20px;
          background-color: #e6edfa;

          span{
            font-size: 16px;
            color: white;
            font-weight: normal;
          }

          
        }

        .btn-download-desktop-app:hover{
          background-color: $mdoc-ui-blue;
        }
      
        


        .btn-wrapper{
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 6px;

          .btn-download-mobile-app{
            width: 145px;
            height: 46px;
            border-radius: 8px;
            border: 1px solid $l-30;

            &.android{
              background: url("../../general/img/download-google-play.png") no-repeat center;
              background-size: cover;
            }
            &.ios{
              background: url("../../general/img/download-app-store.png") no-repeat center;
              background-size: cover;
            }
          }
          span{
            font-size: 12px;
            font-weight: normal;
          }
        }
      }
    }
    .section-img{
      position: relative;
      min-height: 200px;
      flex: 1;
      display: flex;
      background: url("../../general/img/usage-desktop_optimized.jpg") center no-repeat;
      background-size: cover;

      .fade-layer{
        position: absolute;
        right: 0;
        width: 50%;
        height: 100%;
        background: linear-gradient(90deg, rgba(250,250,250,0), rgba(250,250,250,1));
      }
    }
  }
}

@media (max-width: $tablet-breakpoint) {
  .product-summary{
    .boundary{
      padding: 0px;
    }
    .product{
      height: auto;
      flex-direction: column-reverse !important;

      .section-img{
        width: 100%;
        .fade-layer{
          display: none;
        }
      }
      .section-content{
        padding: 40px 0px;
        padding-bottom: 60px;
        .btn-download-desktop-app span{
          font-size: $f-14 !important;
        }

        p, span, {
          font-size: $f-14;
        }

      }
    }
  }
}
