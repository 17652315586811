@import "src/general/colors";
@import "src/general/sizes";

.intro{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .main-logo{
    width: calc(100% - 60px);
    max-width: 650px;
  }
  .title-wrapper{
    position: relative;
    // margin-top: 32px;
    margin-top: 50px;

    padding: 16px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    $border-style: 2px solid $l-20;
    border-top: $border-style;
    border-bottom: $border-style;

    $insert-height: 24px;
    span{
      position: absolute;
      top: -$insert-height/2;
      font-size: 16px;

      padding: 0px 12px;
      background-color: white;
      font-weight: bold;
      color: $mdoc-brand-blue !important;
    }
    h1{
      opacity: 0.8;
      text-align: center;
    }
  }
  .partner{
    margin-top: 36px;
    display: flex;
    flex-direction: column;

    align-items: center;

    .severance{
      display: flex;
      padding-bottom: 12px;

      span{
        padding: 0px 12px;
        font-weight: bold;
        line-height: 24px;
        color: rgb(17,55,120);
        font-size :20px;

        &:first-child{
          color: $main-content;
          opacity: 0.7;
          border-right: 2px solid $l-30;
        }
      }
    }
    .description{
      padding: 0px 16px;
      display: flex;
      flex-direction: column;

      .first-line{
        display: flex;
      }

      span {
        font-size: 16px;
        text-align: center;
        color: rgb(120,120,120);
        line-height: 1.6;

        strong {
          font-size: 16px;
          color: $mdoc-brand-blue;
        }
      }

    }
  }
}

.medias ul{
  list-style: none;
  position: fixed;
  right:20px;
  top: 70%;
  z-index: 1004;
  padding: 10px 30px;
}

.medias ul li{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin: 24px 0;
  transition: .3s;

}



.medias ul li:hover{
  transform: scale(1.9);
}

@media (max-width: $tablet-breakpoint){
  .intro{
    .partner{
      flex-direction: column;
      .description{
        .first-line{
          flex-direction: column;
        }
      }
    }
  }
}


@media (max-width: $mobile-breakpoint) {
  .intro{
    .title-wrapper{
      h1{
        white-space: pre-line;
      }
    }
    .partner{
      flex-direction: column;
      .description{
        *{
          font-size: 14px !important;
        }
      }
    }
  }
}
