$nav-bar-height: 60px;
$page-max-width: 1200px;
$desktop-breakpoint: 1024px;
$tablet-breakpoint: 840px;
$mobile-breakpoint: 500px;

$f-32: 32px;
$f-25: 25px;
$f-18: 18px;
$f-16: 16px;
$f-14: 14px;
$f-12: 12px;

