@import "src/general/colors";

.page.contact{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  p{
    font-size: 16px;
    text-align: center;

  }

  .contact-intro{
    position: relative;
    padding: 60px 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    gap: 8px;
    background-color: rgba(60,100,230,0.1);

    &:before{
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background: url("../general/img/medical-consult.png") no-repeat center;
      background-size: cover;
      opacity: 0.4;
    }
  }
  .contact-form{
    width: 100%;
    padding: 40px 30px;
    padding-bottom: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .form-header{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
    }
    .form-body{
      width: 100%;
      max-width: 500px;
      display: flex;
      flex-direction: column;

      .input-item{
        width: 100%;
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        span{
          color: $sub-content;
          line-height: 28px;
        }
        input, textarea{
          width: 100%;
          border: 1px solid $l-20;
          min-height: 40px;
          padding: 8px;
          border-radius: 8px;
          background-color: $l-5;
        }
        textarea{
          height: 200px;
          resize: none;
        }
      }
      .btn-submit{
        margin-top: 24px;
        width: 100%;
        height: 44px;
        border-radius: 8px;
        background-color: $mdoc-ui-blue;
        color: white;
      }
    }
  }
}
