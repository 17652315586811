@import "src/general/colors";
@import "src/general/sizes";

.footer{
  padding: 80px 0px;
  width: 100%;
  display: flex;
  justify-content: center;

  a:hover{
    opacity: 0.7;
  }

  &.tone-down{
    background-color: $l-5;
  }

  .boundary{
    display: flex;
    //max-width: 800px;
    //background-color: royalblue;
    .section-company-info{
      //background-color: salmon;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      .sub{
        color: $sub-content;
      }
      .logo-type{
        height: 28px;
      }
      .info-group{
        display: flex;
        flex-direction: column;
        gap: 4px;
        .info-item{
          display: flex;
          gap: 8px;
        }
      }

    }
    .section-menu{
      // background-color: red;
      display: flex;
      gap: 24px;
      flex-direction: column;
      // mobile
      // justify-content: center;
      // align-items: center;
      flex:1;

      .section-row{
        display: flex;
        width: 100%;
        flex: 1;
        //background-color: red;
        .menu-group{
          flex: 1;
          width: 200px;
          display: flex;
          flex-direction: column;
          .title{
            border-bottom: 1px solid $l-20;
            font-weight: bold;
            line-height: 36px;
            padding-bottom: 4px;
          }
          a{
            line-height: 28px;
          }
        }
  
        .iconContainer{
          display: flex;
          flex-direction: row;
          background-color: seagreen;
            // .icons a{
            //   display: flex;
            //   color: white;
            //   font-size: 100px;
            //   background-color: yellow;
            //}
        }
      }
      
    }
  }
}


.media ul{
  display: flex;
  list-style: none;
  position: relative;
  flex-direction: row;
  justify-content: end;
  //right:20px;
  padding: 10px auto;
  
}

.media ul li{
  
  padding: 10px 10px;
  transition: .3s;
  
}

.media ul li icon{
  background-color: yellow;
}

.media ul li:hover{
  transform: scale(1.9);
}

.kakaoIcon{
  width: 25px;
}


@media (max-width: $tablet-breakpoint) {
  .footer{
    .boundary{
      flex-direction: column-reverse;

      .section-company-info{

        padding-top: 60px;
        border-top: 1px solid $l-20;
        padding-top: 60px;
        align-items: center;
        .info-group{
          align-items: center;
        }
      }
      .section-menu{
      justify-content: center;
      align-items: center;
        .menu-group{
          *{
            text-align: center;
          }
        }
      }
    }
  }
}
