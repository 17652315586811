@import "src/general/colors";
@import "src/general/sizes";

.gov-timeline{
  width: 100%;
  height: 100%;
  .section-intro{
    padding: 80px 0px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    h1{
      text-align: center;
    }
    p{
      text-align: center;
      font-size: 16px;
    }
  }
  .section-timeline{
    padding: 60px 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $l-5;

    .title{
      color: $sub-content;
    }

    .container-timeline{
      border-top: 2px solid $l-30;
      padding-top: 24px;
      margin-top: 16px;

      display: flex;
      gap: 24px;
      .container-year{
        position: relative;
        min-width: 150px;
        display: flex;
        flex-direction: column;

        .bullet{
          position: relative;
          $bullet-size: 8px;
          margin-right: 16px;
          width: $bullet-size;
          height: $bullet-size;
          border-radius: 50%;
          background-color: $mdoc-ui-blue;

          &:before{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 100%;
            margin: auto;
            width: 8px;
            height: 1px;
            background-color: $mdoc-ui-blue;
            content: "";
          }
        }

        .year-title, .topic{
          &:before{
            position: absolute;
            left: 2px;
            top: 14px;
            height: calc(100% + 12px);
            width: 1px;
            border-right: 1px dashed $mdoc-ui-blue;
            content: "";
          }
        }

        .year-title{
          position: relative;
          display: flex;
          align-items: center;



          .bullet:before{
            display: none;
          }

          span{
            color: $placeholder;
            font-size: $f-25;
            font-weight: bold;
          }
        }

        .timeline{
          margin-top: 12px;
          display: flex;
          flex-direction: column;
          gap: 16px;

          .topic{
            position: relative;
            display: flex;
            align-items: center;

            &:last-child{
              &:before{
                display: none;
              }
            }

            .month{
              width: 40px;
              color: $placeholder;
            }
            .name{
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $tablet-breakpoint) {
  .gov-timeline{
    .section-intro{
      white-space: pre-line;
      p{
        font-size: 14px;
        padding: 0px 40px;
      }
    }
    .section-timeline{
      .title{
        width: calc(100% - 60px);
        text-align: center;
        padding: 0px 16px;
        padding-bottom: 12px;
        border-bottom: 2px solid $l-30;
        white-space: pre-line;
      }
      .container-timeline{
        //width: calc(100% - 60px);
        padding-top: 0px;
        flex-direction: column;
        align-items: center;
        border-top: none;
        .container-year{
          width: 100%;
        }
      }
    }
  }
}


