$l-0: rgb(255,255,255);
$l-5: rgb(250,250,250);
$l-10: rgb(245,245,245);
$l-15: rgb(240,240,240);
$l-20: rgb(235,235,235);
$l-25: rgb(230,230,230);
$l-30: rgb(225,225,225);

$mdoc-ui-blue: rgb(50,80,210);
$mdoc-brand-blue: rgb(33,70,147);

$main-content: rgb(40,40,40);
$sub-content: rgb(160,160,160);
$placeholder: rgba(0,0,0,0.3);

