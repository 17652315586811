@import "src/general/sizes";
@import "src/general/colors";

/* roboto-regular - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./general/fonts/roboto-v27-latin-ext_latin/roboto-v27-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./general/fonts/roboto-v27-latin-ext_latin/roboto-v27-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./general/fonts/roboto-v27-latin-ext_latin/roboto-v27-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./general/fonts/roboto-v27-latin-ext_latin/roboto-v27-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
  url('./general/fonts/roboto-v27-latin-ext_latin/roboto-v27-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./general/fonts/roboto-v27-latin-ext_latin/roboto-v27-latin-ext_latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./general/fonts/roboto-v27-latin-ext_latin/roboto-v27-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./general/fonts/roboto-v27-latin-ext_latin/roboto-v27-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./general/fonts/roboto-v27-latin-ext_latin/roboto-v27-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('./general/fonts/roboto-v27-latin-ext_latin/roboto-v27-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
  url('./general/fonts/roboto-v27-latin-ext_latin/roboto-v27-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./general/fonts/roboto-v27-latin-ext_latin/roboto-v27-latin-ext_latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}


/* noto-sans-kr-regular - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('./general/fonts/noto-sans-kr-v13-korean/noto-sans-kr-v13-korean-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./general/fonts/noto-sans-kr-v13-korean/noto-sans-kr-v13-korean-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./general/fonts/noto-sans-kr-v13-korean/noto-sans-kr-v13-korean-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./general/fonts/noto-sans-kr-v13-korean/noto-sans-kr-v13-korean-regular.woff') format('woff'), /* Modern Browsers */
  url('./general/fonts/noto-sans-kr-v13-korean/noto-sans-kr-v13-korean-regular.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}

/* noto-sans-kr-700 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('./general/fonts/noto-sans-kr-v13-korean/noto-sans-kr-v13-korean-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./general/fonts/noto-sans-kr-v13-korean/noto-sans-kr-v13-korean-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./general/fonts/noto-sans-kr-v13-korean/noto-sans-kr-v13-korean-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('./general/fonts/noto-sans-kr-v13-korean/noto-sans-kr-v13-korean-700.woff') format('woff'), /* Modern Browsers */
  url('./general/fonts/noto-sans-kr-v13-korean/noto-sans-kr-v13-korean-700.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}

* {
  margin: 0px;
  padding: 0px;
  font-family: "Roboto", "Noto Sans KR", Helvetica, sans-serif;
  box-sizing: border-box;
  font-size: 14px;
  color: $main-content;
  
}

a{
  text-decoration: none;
  color: $main-content;
}

h1{
  font-size: $f-32;
}

h2{
  font-size: $f-25;
}

.boundary{
  padding: 0px 24px;
  width: 100%;
  max-width: $page-max-width;
}

.app{
  padding-top: $nav-bar-height;
  width: 100%;
  //overflow-x: hidden;
}

.landingContainer{
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.2);
  justify-content: center;
  
}
.LandingImg{
 
  
  width: 100%;
  height: 100%;
  background-image:  url( "general/img/LandingTest.png" );
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 100;
}

button{
  position: relative;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  overflow: hidden;
  &:disabled{
    opacity: 0.3;
    pointer-events: none;
  }

  &:hover{
    &:before{
      display: block;
    }
  }

  &:before{
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.2);
    display: none;
  }
}


@media (max-width: $tablet-breakpoint) {
  h1{
    font-size: $f-25;
  }
}
